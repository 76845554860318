.Event-description {
    width: 100%;
    text-align: center;
    /* color: #050a30; */
    margin-top: 6rem;
    margin-bottom: 4rem;
}
.Event-description h2 {
    text-transform: capitalize;
    margin-bottom: 1rem;
    color: white;
    font-style: normal;
    font-weight: 600;
    /* font-size: 96px; */
    font-size: min(80px, 8.8vw);
    line-height: 98%;
    /* or 94px */

    text-align: center;
    letter-spacing: -0.02em;
    margin: 40px 0 40px 0;
}
.EventList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    /* padding-top: 3rem;
    padding: 2rem, */
    margin-bottom: 3rem;

    z-index: 1;
    position: relative;
}

.show-more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 4rem; */
    margin-bottom: 3rem;
}
.show-more button {
    height: 40px;
    width: 150px;
    padding: 10px;
    background-color: #1d508f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} 
.animated-button {
    position: relative;
    animation: moveUpDown 2.5s infinite;
  }
  
  @keyframes moveUpDown {
    0%, 100% {
      top: 0;
    }
  
    50% {
      top: 10px; /* Adjust the distance as needed */
    }
  }
@media (max-width: 768px) {
    .EventList {
        width: 100%;
        padding: 1rem;
        /* padding-left: 5%; */
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-around; */
    }
}