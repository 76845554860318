:root{
    --dark-blue: #3B3F50;
    --red: #FE4343;
    --blue: #031c4b;
    --gray: #cacad2; /* #D9D9D9 */ /* #ddddea */
    --white: #F4F4F4;
    --white-transparent: #f4f4f4a1 /* #f4f4f438 */;
    --black: #383838;
    --black-transparent: #0e0e0ea1;
}

.header header.navBar {
    background-color: #91939a; /* rgba(253, 249, 249, 0.53) */
    background-blend-mode: luminosity;
    box-shadow: 8px 10px 42px rgba(0, 0, 0, 0.42);

    border-style: none;
    border-radius: 50px;
    
    margin-top: 36px;
    margin-right: 61px;
    margin-left: 61px; 

    gap: 40px;
    
    padding-right: 70px;
    padding-left: 70px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    min-width: 377px;
}
.header header.navBar > .logo{
    margin-right: auto;
}
.header header.navBar > a {
    color: black;
    margin-inline: 5px;
    text-decoration: none;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
}
.header header.navBar>.login {
    width: 150px;
    height: 48px;
    background: linear-gradient(90deg, #C436B9 -9.67%, #19A6DB 113%);
    border-radius: 50px;
    border-style: none;
    color: white;
    font-weight: bold;
}




.header header.navBar.light{
    background-color: transparent;
}
.header header.navBar.light > .logo{
    filter: invert(1) hue-rotate(189deg) grayscale(0.2);
}
.header header.navBar.light > a {
    color: #ececec;
    text-wrap: nowrap;
}








/* .panel {
    box-shadow: 2px 0px 14px 4px #252525a8;
} */

.header{
    height: fit-content;
    /* background-color: var(--gray); */
    padding-bottom: 20px;
}

.header > .navBar{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}

.header > .navBar{
    list-style: none;
    margin: 0;
}
.header > .navBar a{
    text-decoration: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: var(--dark-blue);
}

.header > .m-navBar-wrapper{
    display: none;

    width: 100%;
    height: 80px;

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    transition: height .35s ease;

    opacity: .9;

    /* box-shadow: 0 2px 6px 2px #38381c; */
    box-shadow: 0 2px 6px 1px #161d31;
    background-color: var(--white);
    transition: background-color .2s;
}
.header > .m-navBar-wrapper > .m-navBar{
    position: relative;
    background-color: transparent;
    height: 300px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: height .35s ease,
                background-color .35s ease;
    overflow: hidden;
}
.header > .m-navBar-wrapper[data-expanded=true],
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar{
    height: 330px; /* 287px */ /* 400px */
    /* background-color: var(--white); */
    background-color: #001429/*#383838*/;
}
.header > .m-navBar-wrapper[data-expanded=true] .logo {
    filter: invert(1) hue-rotate(189deg) grayscale(0.2);
}
.header > .m-navBar-wrapper > .m-navBar > .top{
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: auto;
}
.header > .m-navBar-wrapper > .m-navBar > .top > button{
    border: none;
    background: transparent;
    position: absolute;
    height: 30px;
    aspect-ratio: 1;
    top: 25px;
    right: min(30px, 5vw);
    padding: 0;
}
.header > .m-navBar-wrapper > .m-navBar > .top > button svg {
    transform: scale(1.3, 1);
}
.header > .m-navBar-wrapper > .m-navBar > .top > button path{
    stroke-dasharray: 6 3 6 3 6 25;
    stroke-dashoffset: 0;
    stroke: var(--black);
    transition: stroke .5s ease, stroke-dasharray .5s ease, stroke-dashoffset .5s ease;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .top > button path{
    stroke-dasharray: 6 9 6 28; /* 26 49 7 7 10 */
    stroke-dashoffset: 24; /* 50 */
    stroke: var(--white);
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .top{
    /* margin-top: -27px; */
    justify-content: center;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .top > button svg {
    transform: scale(1.3, 1.3);
}
.header > .m-navBar-wrapper > .m-navBar > .middle{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}
.header > .m-navBar-wrapper[data-expanded=true] > .m-navBar > .middle{
    margin-top: 0;
    margin-bottom: auto;
}
.header > .m-navBar-wrapper > .m-navBar a{
    text-decoration: none;
    color: var(--white);
    text-align: center;
    font-size: 1.2rem;
}
.header > .m-navBar-wrapper > .m-navBar a.selected{
    border-bottom-style: solid;
    border-color: #4a6495; /* var(--blue) */
    border-width: 2px;
}
.header > .m-navBar-wrapper.transparent{
    background-color: transparent;
    box-shadow: unset;
}
.header > .m-navBar-wrapper.transparent .logo{
    filter: invert(1) hue-rotate(189deg) grayscale(0.2);
}
.header > .m-navBar-wrapper.transparent > .m-navBar > .top > button path{
    stroke: var(--white);
}

.highlighter{
    position: absolute;
    
    --right: 50px;
    --width: 70px;
    
    width: var(--width);
    height: 2px;
    background-color: var(--red);
    right: var(--right);
    bottom: 30px;

    display: none;
}

.header .logo{
    width: 59px;
    height: auto;
    /* filter: invert(1) hue-rotate(189deg) grayscale(0.2); */
}

@media screen and (max-width: 767px) {
    .header > .m-navBar-wrapper{
        display: block;
    }

    .header > header.navBar{
        display: none;
    }
}
@media screen and (max-width: 900px) {
	.header header.navBar {
		gap: 18px;
	}
}
