.EventPageRoot {
    background-image: radial-gradient(#5c616b, #171F2E);
    padding-top: 1px; /* 36px */
}
.EventPageRoot > header.navBar {
    margin-top: 0;
}

.EventPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #171F2E; */
    /* height: 100%; */
}
.EventPage .event-container {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */

    background-color: chartreuse;
    width: 80%;     
    height:90%;
    backdrop-filter: blur(8px) saturate(200%);
    -webkit-backdrop-filter: blur(8px) saturate(200%);
    background-color: rgba(90, 90, 95, 0.55);
    border-radius: 12px 12px 0 0;
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-bottom: none;
    padding-bottom: 70px;
}


.EventPage .title {
    margin-bottom:30px;
    z-index: 3;
    margin-top: 50px;
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    /* font-size: min(80px, 8.8vw); */
    font-size: min(52px, 6.3vw);
    line-height: 98%;
}

.EventPage .video-wrapper {
    width:100%;
    margin-bottom: 20px;
}

.EventPage .video {
    border: 0;
    width:100%;
}

.EventPage .event-details {
    width: 90%;
}

.EventPage .details-line {
    display: flex;
    flex-wrap: wrap;
    color: rgb(255, 255, 255);
    justify-content: space-between;
    margin-bottom: 10px;
    text-align: justify;
}

.EventPage .detail {
    margin: 0;
}

.EventPage .description {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    color: rgb(255, 255, 255);
    text-align: justify;
    line-height: 2;
}

@media (max-width: 768px) {
    .EventPage .event-container {
        width: 100%;
        border-radius: 0;
    }
    .EventPage .title {
        /* margin-bottom:60px;
        font-size: 30px; */
        margin-top: 30px;
    }
    .EventPage .event-details{
        width: 95%;
    }
}