:root{
    --dark-blue: #3B3F50;
    --red: #FE4343;
    --blue: #031c4b;
    --gray: #cacad2; /* #D9D9D9 */ /* #ddddea */
    --white: #F4F4F4;
    --white-transparent: #f4f4f4a1 /* #f4f4f438 */;
    --black: #383838;
    --black-transparent: #0e0e0ea1;
}

/* .panel {
    box-shadow: 2px 0px 14px 4px #252525a8;
} */

.aboutUs{
    height: fit-content;
    /* background-color: var(--gray); */
    padding-bottom: 20px;
}

.aboutUs > .main{
    position: relative;
    width: calc(100% - 20px);
    height: max(50vh, 350px);
    margin-left: 10px;
    overflow: clip;
    
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));

    margin-top: 5px;
}
.aboutUs > .main > .rot{
    height: 150%;
    width: calc(100vw - 30px);
    display: grid;
    grid-template-columns: calc(60vw - 90px) 22vw 22vw; /* calc(60vw - 100px) 20vw 20vw */
    grid-template-rows: repeat(3, 1fr);
    gap: 20px;
    /* grid-auto-rows: minmax(100px, auto); */
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(5deg);
    padding: 15px;
    box-sizing: border-box;
}

/* .aboutUs > .main > .rot > *{
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
} */

.aboutUs > .main > .rot > .left{
    position: relative;
    background-color: #c5c4c4;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aboutUs > .main > .rot > .left::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 20vw;
    right: 99%;
    background: inherit;
}
.aboutUs > .main > .rot > .bgAbout{
    background-color: var(--white);
}

.aboutUs > .main > .rot > .bgAbout:nth-child(1){
    background-image: url(./img/1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 2;
    grid-row: 1 / 3;
}
.aboutUs > .main > .rot > .bgAbout:nth-child(2){
    background-image: url(./img/2.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 3;
    grid-row: 1;
}
.aboutUs > .main > .rot > .bgAbout:nth-child(3){
    background-image: url(./img/3.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 2;
    grid-row: 3;
}
.aboutUs > .main > .rot > .bgAbout:nth-child(4){
    background-image: url(./img/4.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 3;
    grid-row: 2 / 4;
}

.aboutUs > .main > .rot > .left > .content{
    width: calc(100% - 40px);
    max-width: 500px;
    height: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;

    transform: rotate(-5deg);
}
.aboutUs .title{ /* .aboutUs > .main > .rot > .left > .content > .title */
    color: #3B3F50;
    font-size: max(min(3rem, 5vw), 25px);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
}

.aboutUs .description{ /* .aboutUs > .main > .rot > .left > .content > .description */

    width: 100%;
    color: #272626;
    font-size: max(min(.9rem, 1.3vw), 10px);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    }
.aboutUs .startBtn{ /* .aboutUs > .main > .rot > .left > .content > .startBtn */
    color: #FFF;
    text-align: center;
    font-size: max(min(1rem, 1.6vw), 12px);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    background-color: var(--red);
    padding: 7px 40px;
    border-radius: 10px;
}


@media screen and (max-width: 767px) {
    .aboutUs > .main{
        margin-top: -15px;
        margin-left: 0;
        overflow: unset;
        width: 100vw;
    }
    .aboutUs > .main > .rot{
        width: 100%;
        height: calc(100% - 30px);
        transform: translateY(-50%);
        grid-template-columns: calc(60vw - 20px) calc(20vw - 20px) calc(20vw - 20px); /*calc(60vw - 100px) 20vw 20vw*/
        padding: 0;
    }
    .aboutUs > .main > .rot > .left{
        background-color: var(--black-transparent);
        grid-column: 1 / 4;
        grid-row: 1 / 4;
        width: 100vw;
    }
    .aboutUs > .main > .rot > .left::before{
        display: none;
    }
    .aboutUs > .main > .rot > .left > .content{
        transform: rotate(0deg);
        align-items: center;
        margin-right: 20px;
    }
    .aboutUs > .main > .rot > .left > .content > .title{
        color: var(--white);
    }
    .aboutUs > .main > .rot > .left > .content > .description{
        color: var(--gray);
        font-size: max(min(1.6rem, 2vw), 12px);
    }
    .aboutUs > .main > .rot > .bgAbout{
        opacity: 0.3; /* 0.2 */
    }
    
    .aboutUs > .main > .rot > .bgAbout:nth-child(1){
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }
    .aboutUs > .main > .rot > .bgAbout:nth-child(2){
        grid-column: 2 / 4;
        grid-row: 1;
    }
    .aboutUs > .main > .rot > .bgAbout:nth-child(3){
        grid-column: 1 / 2;
        grid-row: 3;
    }
    .aboutUs > .main > .rot > .bgAbout:nth-child(4){
        grid-column: 2 / 4;
        grid-row: 2 / 4;
    }
}