@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body .Form {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 40px 20px;
    /* background: linear-gradient(90deg, #C436B9 -9.67%, #19A6DB 113%); */
    /* background-image: linear-gradient(135.59deg, rgba(88, 130, 193, 0.49) 1.28%, rgba(88, 130, 193, 0.11) 96.26%),
    linear-gradient(0deg, rgba(110, 129, 166, 0.37), rgba(110, 129, 166, 0.37)); */
    background-image: radial-gradient(#5c616b, #171F2E);
}

.Form .logo{
    filter: invert(1) hue-rotate(189deg) grayscale(0.2);
}

.Form .flex{
    display:flex;
    flex-direction: row;
}
.Form .container {
    position: relative;
    max-width: 700px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.Form .container header {
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    text-align: center;
}

.Form .container .form {
    margin-top: 20px;
}
.Form .form .input-box {
    width: 100%;
    margin-top: 20px;
    
}
.Form .form .input-box select {
    height: 50px;
    border: none;
    border-radius: 6px;
    padding-left: 10px;
    width: 100%;
}
.Form .select{
    width: 70%;
    margin-top: 30px;
}
.Form .select-box{
    /* height: 100%; */
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 6px;
}
.Form .select-box:focus-within{
    border-color:#1b3563;
}
.Form .select-box select {
    padding-inline: 10px;
    border-radius: 5px;
}

.Form .input-box label {
    color: #333;

}

.Form .form :where(.input-box input) {
    position: relative;
    height: 50px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0 15px;
}

.Form .input-box input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.Form .form .column {
    display: flex;
    column-gap: 15px;
}

.Form .form .gender-box {
    margin-top: 20px;
}

.Form .gender-box h3 {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
}
.Form .form :where(.gender-option, .gender) {
    display: flex;
    align-items: center;
    column-gap: 50px;
    flex-wrap: wrap;
}

.Form .form .gender {
    column-gap: 5px;
}

.Form .gender input {
    accent-color: rgb(130, 106, 251);
}

.Form .form :where(.gender input, .gender label) {
    cursor: pointer;
}

.Form .gender label {
    color: #707070;
}

.Form .address:where(input, .select-box) {
    margin-top: 15px;
}
.Form .select-box select {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: #707070;
    font-size: 1rem;
}

.Form .form button {
    height: 55px;
    width: 60%;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #1b3563; /* cornflowerblue; */
}

.Form .form button:hover {
    background: rgb(88, 56, 250);
}

@media screen and (max-width: 500px) {
    .Form form.column {
        flex-wrap: wrap;
    }

    .Form .form:where(.gender-option, .gender) {
        row-gap: 15px;
    }
}
