.Contact {
    width: 100%;
    min-height: 80vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form {
    width: 50%;
    padding: 1rem;
    padding-left: 1.5rem;
}
.contact-form h2 {
    color: white;
    font-family: Assistant;
}
.contact-form p {
    font-family: Assistant;
    margin: 10px 0 10px 0;
    color: white;
}
.contact-form input , .contact-form textarea {
    width: 95%;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    resize: none;
}
.contact-form input {
    height: 50px;
}
.contact-form input:focus , textarea:focus {
    outline: none;
}
.contact-form button {
    width: 8rem;
    height: 2rem;
    background-color: #1d508f;
    border: none;
    color: white;
    border-radius: 1rem;
    cursor: pointer;
}
@media (max-width: 768px) {
   .contact-form {
        width: 90%;
        height: 50%;
    }
    .contact-form {
        padding: 0.5rem;
    }
}