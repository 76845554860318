.Event-Card {
    width: 20rem;
    /* min-height: 25rem; */
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
    border-radius: 0.6rem;
    margin-bottom: 2rem;
    /* border: 3px solid #1d508f; */
}
.Event-Card .Event-pic {
    width: 100%;
    /* height: 45%; */
    border-radius: 0.5rem 0.5rem 0 0;
    position: relative;
}

.Event-Card .Event-pic .img-card {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 10rem;
    min-width: 100%;
    min-height: 10rem;
    border-radius: 0.5rem 0.5rem 0 0;
}
.Event-Card .eventDetail {
    padding: 0.5rem;
    padding-right: 0.5rem;
    position: relative;
    width: 100%;
    /* cursor: pointer; */
    /* height: 42%; */
    font-family: 'Assistant';
}
.eventDetail p {
    font-size: 0.9rem;
    color: rgb(97, 97, 97);
    line-height: 1.5;
    text-transform: capitalize;
    text-align: justify;
    padding: 0.2rem 0.4rem 0 0.4rem;
}
#place {
    font-size: 0.7rem;
}
.eventDetail h1 {
    text-transform: uppercase;
    font-family: Assistant;
    font-size: 25px;
    color: #1d508f;
    margin-bottom: 0.3rem;
    /* margin-top: 10px; */
    text-align: left;
}

.event-description p {
    color: #050a30;
    font-weight: 300%;
    min-height: 5rem;
}
.btn-explore {
    width: 100%;
    padding: 0.5rem;
    background-color: #1d508f;
    color: white;
    border: none;
    /* border-bottom: 1px solid white; */
    border-radius: 0 0 0.5rem 0.5rem;
    cursor: pointer;
    height: 52px; /* 13% */
}
.btn-explore > a {
    text-decoration: none;
    color: white;
}
.coming {
    background-image: linear-gradient(45deg, #175392, #122e4b);
}
.btn-accept {
    width: 100%;
    padding: 0.5rem;
    background-color: #c70039;
    color: white;
    border: none;
    /* border-bottom: 1px solid white; */
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
    height: 10%;
}
@media (max-width: 768px) {
    .Event-Card {
        /* width: 90%; */
        /* margin-right: 1rem; */
        /* height: 27rem; */
        margin-left: 1rem;
    } 
}