.Specification {
    width: 100%;
    min-height: 70vh;
    height: auto;
    /* background-color: #1b2643; */
    text-align: center;
}
.Specification h5 {
    color: white;
    /* font-family: Assistant; */
    font-family: "Poppins", sans-serif;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    /* font-size: 96px; */
    font-size: min(80px, 8.8vw);
    line-height: 98%;
    /* or 94px */

    text-align: center;
    letter-spacing: -0.02em;
    margin: 80px 0 40px 0;
}
.Specification-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}