.alertContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* or inset: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: #272e3cd4;
}
.alertBox {
    padding: 20px;
    border-radius: 5px;
    background-color: #d0d6e3;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.alertBox p {
    display: flex;
    align-items: center;
    gap: 10px;
}
.alertIcon {
    width: 25px;
    min-width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #6d6d6d;
    color: #efefef;
}