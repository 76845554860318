.Cell{
    display: flex;
    flex-direction: row;
    gap: 10px;

    --cell-background: #172335d9;
    --indicator-color: #1e5394; /* #1466ff */
    --text-color: #f4f4f4;

    background-color: var(--cell-background);
    color: var(--text-color);

    font-family: "Poppins", sans-serif;
}
.Cell > .indicator {
    background-color: var(--indicator-color);
    width: 2px;
    position: relative;
    color: transparent;
    user-select: none;
}
.Cell > .indicator::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 12px;
    aspect-ratio: 1;
    right: 1px;
    top: 10px;
    transform: translateX(50%);
    border-radius: 50%;
    background-color: var(--cell-background);
    box-shadow: 0 0 0 4px var(--indicator-color),
                0 0 0 6px var(--cell-background);
}
.Cell:first-of-type > .indicator {
    margin-top: 12px;
}
.Cell:first-of-type > .indicator::before{
    width: 0;
    top: 0px;
    border-radius: 0;
    background-color: unset;
    box-shadow: unset;
    border: 10px transparent solid;
    border-top-color: var(--indicator-color);
}
.Cell > .right {
    padding: 10px;
    width: 50%;
    box-sizing: border-box;
}
.Cell .title{
    margin: 0;
}
.Cell .description{
    margin: 0px; /*4px*/
    color: #7b8084;
}

.Cell > .left {
    padding: 10px;
    text-align: right;
    width: 50%;
    box-sizing: border-box;
}
.Cell > .left > h4{
    width: auto;
}