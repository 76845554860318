@font-face {
    font-family: 'Poppins';
    src: url('../../fonts/Poppins/Poppins-Black.ttf') format('ttf');
}
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

html, body{
    scroll-behavior: smooth;
    overflow-x: hidden;
    max-width: 100vw;
    background-color: #171F2E;
}


.home .content {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: calc(100vh - 50px); */
    /* margin-top: 50px; */
    height: 100vh;
    /* margin-left: 350px; */
    position: relative;
    filter: drop-shadow(0 0 130px #F5F5F5 );
    transform: translateZ(0);
    overflow: hidden; /* visible clip */
}

.home .content img{
    position: absolute;
    right: -95px;
    height: 100%;
}

@media screen and (max-width: 480px) {
    .home .content img {
        position: absolute;
        top: 60px;
        right: 119px;
        transform: translateX(50%);
        height: 100%;
        max-height: 608px;
        max-width: 420px;
        /* max-width: 100%; */
    }

    .home .content {
        height: 500px;
    }
    /* .home .typing-demo {
        top: 300px;
    } */
}


.home.bg {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #171F2E;
    overflow-x: hidden;
    overflow-y: auto;

    
}

.home.bg>.bottomGradient {
    position: fixed;
    width: 50%;
    height: 50%;
    right: 0;
    bottom: 0;

    transform: translate(50%, 50%);

    background: linear-gradient(51.06deg, rgba(25, 166, 219, 0.25) 0.87%, rgba(146, 89, 247, 0.25) 7.31%, rgba(142, 93, 246, 0.25) 13.75%, rgba(136, 98, 245, 0.25) 20.19%, rgba(128, 107, 244, 0.25) 26.63%, rgba(117, 117, 242, 0.25) 33.07%, rgba(104, 130, 240, 0.25) 39.51%, rgba(89, 144, 238, 0.25) 45.95%, rgba(74, 159, 235, 0.25) 52.39%, rgba(59, 173, 233, 0.25) 58.84%, rgba(46, 186, 231, 0.25) 65.28%, rgba(35, 196, 229, 0.25) 71.72%, rgba(27, 205, 228, 0.25) 78.16%, rgba(21, 210, 227, 0.25) 84.6%, rgba(17, 214, 226, 0.25) 91.04%, rgba(16, 215, 226, 0.25) 97.48%);
    mix-blend-mode: normal;
    filter: blur(109px);
}


/* Cyber Processing Unit */
.home .typing-demo {
    filter: contrast(.95);
    box-shadow: 3px 3px 19px 4px #000000c4;
}
.home .typing-demo h1:nth-child(1) {
    color: #19A6DB;
    font-family: 'Fira Code', monospace;
    font-weight: 900;
    font-size: min(3vw, 27px); /* min(3vw, 30px) */
    margin: 0 0 0 10%; /* 0 0 0 20% */
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    width: 250px;
    letter-spacing: 0px;
    animation: type 2s steps(30, end) forwards;
}

.home .typing-demo h1:nth-child(2) {
    opacity: 1;
    font-family: "Work Sans", sans-serif;
    margin: 1% 7% 7% 7%; /* 1% 7% 7% 10% */
    background: linear-gradient(1deg, #C436B9 -9.67%, #19A6DB 113%);
    font-weight: 800;
    font-size: min(8vw, 90px);
    /* width: 694px; */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden;
    /* animation: shring-animation 2.2s steps(40, end) 2s forwards, blink .5s step-end infinite alternate; */
    letter-spacing: -1px;
}
@keyframes shring-animation {
    0% {
        background-position: 0 0;
        opacity: 0;
        width: 0;
    }

    1% {
        background-position: 0 0;
        opacity: 1;
        border-right: 0px solidtransparent;
    }

    50% {
        background-position: 150px 0;
        opacity: 1;
        
    }

    100% {
        background-position: 200px 0;
        opacity: 1;
        border-right: 0px solid;
    }
}
@keyframes type {
    0% {
        width: 0;
    }

    1%,
    99% {
        border-right: 1px solid transparent;
    }

    100% {
        border-right: none;
    }
}

.home .typing-demo {


    position: absolute;
    width: 61vw;
    aspect-ratio: 4;
    /* left: min(-3vw, 30px); */
    top: 300px;
    font-weight: 80;
    font-size:80px;
    line-height: min(7vw, 70px);
    letter-spacing: -0.040em;
    text-transform: uppercase;

    background-image: url('../../img/blackTerminalTransparent.png');
    padding-top: 50px;
    margin-left: 40px;
}


.footer {
    background-color: #171F2E;
    color: #fff;
    text-align: center;
}

.footer__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(268.44deg, #04426A -15.24%, rgba(108, 172, 228, 0.25) 0%, rgba(108, 172, 228, 0.25) 6.16%, rgba(1, 13, 24, 0.25) 114.25%);
    padding: 20px;
}

.footer__section {
    margin-bottom: 20px;
}

.footer__icons {
    display: inline-block;
}

.footer__icon {
    font-size: 24px;
    margin-right: 15px;
}
