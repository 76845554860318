.trainings {
    position: relative;
    background-image: url("./img/bgDevRobotics2.png");
    background-position: center;
    background-size: cover;
    --timeline-background: #172335;
    background-color: var(--timeline-background);
    margin-bottom: 50px;
}
.trainings::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg,#1b2643 2%, transparent);
}

.trainings.expanded::after {
    background: linear-gradient(0deg,#1b264355 2%, #1b264320);
}

.trainings > .moreBtnRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.trainings > .moreBtnRow > .moreBtn {
    position: absolute;
    bottom: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    color: #f4f4f4;
    font-family: "Poppins", sans-serif;
    z-index: 10;
    background-color: #1d508f;
}



.trainings .titlevent{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    /* font-size: 96px; */
    font-size: min(80px, 8.8vw);
    line-height: 98%;
    /* or 94px */

    text-align: center;
    letter-spacing: -0.02em;

    color: #19A6DB;

    --cell-background: #172335d9;
    background-color: var(--cell-background);
    margin-bottom: 0;
    padding-block: 20px;
}