.sponsors {
    margin-bottom: 120px;
    background-color: white;
    padding: 100px 40px 100px 40px;
}
.sponsors h2 {
    text-transform: capitalize;
    margin-bottom: 1rem;
    color: #122e4b;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    margin: 0px 0 20px 0;
    text-align: center;
}
.sponsors .sponsors-slide{
    min-height: 100px;
    overflow: hidden;
    /* animation: 50s slideLeft  linear infinite;  */
    display: inline-block;
}
.sponsors .sponsordiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
    margin-inline: 20px;
}
.sponsors .sponsor-logo {
    display: inline-block; 
    margin-right: 20px; 
    background-color: rgba(255, 255, 255, 0.4);
    max-width: 90px;
    min-width: 90px;
    height: 90px;
    position: relative;
    margin: 0 25px 0 25px;
}
.sponsors .sponsor-logo img {
    width: 100px;
    pointer-events: none;
    user-select: none;
}
/* @keyframes slideLeft {
    from {
        transform: translateX(0); 
    }
    to {
        transform: translateX(-100%); 
    }
} */