.SpecificationItem {
    width: 17rem;
    height: 25rem;
    background-color: white;
    position: relative;
    border-radius: 17px;
    margin: 20px 20px 50px 20px;
}
.SpecificationItem.cls {
    opacity: 0.85 !important;
    box-shadow: 0 0 7px 1px #4d4d4d87;
}
.SpecificationItem .SpecificationItem_header {
    width: 100%;
    height: 90%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-radius: 15px 15px 0 0;
}
.SpecificationItem .SpecificationItem_header img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    border-radius: 15px 15px 0 0;
}
.SpecificationItem .SpecificationItem_content {
    width: 100%;
    height: 10%;
    text-align: center;
    position: absolute;
    bottom: 0%;
}
.SpecificationItem_content button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 15px;
    /* font-family: Assistant; */
    font-family: "Poppins", sans-serif;
    border: none;
    /* background-color: #3a6ea5; */
    background-image: linear-gradient(45deg, #175392, #122e4b);
    border-radius: 0 0 15px 15px;
    color: white;
    cursor: pointer;
}
.SpecificationItem .notAval {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: black;
    position: absolute;
    top: 0;
    opacity: 0.97;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.SpecificationItem .notAval img {
    max-width: 80%;
    max-height: 50%;
}